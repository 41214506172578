import React from "react";
import '../enterprise/History.css';

function History() {
    return (
        <>
            <section id="timeline">

                <div className="tl-item">
                    <div className="tl-bg" style={{ backgroundImage: 'url(./images/nosotros/nosotros-1.webp)' }} />
                    <div className="tl-year">
                        <p className="f2 heading--sanSerif" style={{ fontSize: "20px" }}>Orgullosos</p>
                    </div>
                    <div className="tl-content">
                        {/* <h1 className="f3 text--accent ttu">Lorem ipsum dolor sit</h1> */}
                        <p style={{ fontSize: "19px" }}>Fundada en el año 2020, luego de años de experiencia en Retail y micro distribución, se logró un crecimiento sostenido y el comienzo de relaciones con plantas fabricantes de minerales.
                            De esta manera fuimos evolucionando hasta crear una empresa de distribución en el pais.
                        </p>
                    </div>
                </div>

                <div className="tl-item">
                    <div className="tl-bg" style={{ backgroundImage: 'url(./images/nosotros/nosotros-2.webp)' }} />
                    <div className="tl-year">
                        <p className="f2 heading--sanSerif" style={{ fontSize: "20px" }}>Lubricantes</p>
                    </div>
                    <div className="tl-content">
                        {/* <h1 className="f3 text--accent ttu">Vestibulum laoreet lorem</h1> */}
                        <p style={{ fontSize: "20px" }}>Contamos con más de 3 años de experiencia en el ramo de importación de lubricantes desde Dubai.</p>
                    </div>
                </div>

                <div className="tl-item">
                    <div className="tl-bg" style={{ backgroundImage: 'url(./images/nosotros/nosotros-3.webp)' }} />
                    <div className="tl-year">
                        <p className="f2 heading--sanSerif" style={{ fontSize: "20px" }}>La</p>
                    </div>
                    <div className="tl-content">
                        {/* <h1 className="f3 text--accent ttu">Phasellus mauris elit</h1> */}
                        <p style={{ fontSize: "19px" }}>Hoy nos enorgullece representar de forma exclusiva de marcas internacionales como: GULF, VALVOLINE, ARMAX Y MILLARD en el mercado de comercialización de lubricantes automotrices.</p>
                    </div>
                </div>

                <div className="tl-item">
                    <div className="tl-bg" style={{ backgroundImage: 'url(./images/nosotros/nosotros-4.webp)' }} />
                    <div className="tl-year">
                        <p className="f2 heading--sanSerif" style={{ fontSize: "20px" }}>Mundial</p>
                    </div>
                    <div className="tl-content">
                        {/* <h1 className="f3 text--accent ttu">Mauris vitae nunc elem</h1> */}
                        <p style={{ fontSize: "19px" }}>Este crecimiento nos ha permitido expandir nuestra experiencia y formar parte de un Holding de empresas con reconocimiento Internacional como Grupo La Mundial, que está comprendido por Ruedas la Mundial, Baterias La Mundial, Cauchos la mundial y Equipamiento La Mundial.
                        </p>
                    </div>
                </div>

            </section>
        </>
    )
}

export default History;