import React, { useEffect } from 'react';
import Slider from 'react-slick';
import "../sections/PartnerSection.css";
import "bootstrap/dist/css/bootstrap.min.css";

function PatnerSection() {

    useEffect(() => {
        const customerLogos = document.querySelector('.customer-logos');
        if (customerLogos) {
            // Aquí puedes realizar la inicialización de Slick
            // utilizando el elemento customerLogos
            // y las opciones de configuración correspondientes
        }
    }, []);

    const settings = {
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 520,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };

    return (
        <>
            {/*./images/ */}

            <div class="divpart slider">
                <Slider {...settings}>
                    <div class="slide-gulf"><img src="../images/cintillo/gulf-logo-v3.png" /></div>
                    <div class="slide-valvoline"><img src="../images/cintillo/valvoline-res.png" /></div>
                    <div class="slide-armax"><img src="../images/cintillo/armax-logo.png" /></div>
                    <div class="slide-millard"><img src="../images/cintillo/millard-blue.png" /></div>
                    <div class="slide-gulf"><img src="../images/cintillo/gulf-logo-v3.png" /></div>
                    <div class="slide-valvoline"><img src="../images/cintillo/valvoline-res.png" /></div>
                    <div class="slide-armax"><img src="../images/cintillo/armax-logo.png" /></div>
                    <div class="slide-millard"><img src="../images/cintillo/millard-blue.png" /></div>
                    <div class="slide-gulf"><img src="../images/cintillo/gulf-logo-v3.png" /></div>
                    <div class="slide-valvoline"><img src="../images/cintillo/valvoline-res.png" /></div>
                    <div class="slide-armax"><img src="../images/cintillo/armax-logo.png" /></div>
                    <div class="slide-millard"><img src="../images/cintillo/millard-blue.png" /></div>
                </Slider>
            </div >
        </>
    );
}

export default PatnerSection;
