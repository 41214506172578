import React from "react";
import '../pages/Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faEnvelopeOpen, faLocationDot, faMap } from '@fortawesome/free-solid-svg-icons';
import Footer from '../Footer';

function Contact() {

    return (
        <>
            <div className="cuerpo-contactos">
                <div className="content-main">
                    <div className="card-contactos">

                        <div className="icon"><FontAwesomeIcon className="material-icons md-36" icon={faPhone} /></div>
                        <p className="title">Teléfono</p>
                        <div className="text">
                            <a className="links" href="https://wa.link/byj1si" target="_blank"><FontAwesomeIcon icon={faWhatsapp} /></a> &nbsp; &nbsp;
                            <a className="links" href="tel:+584246328415" target="_blank"><FontAwesomeIcon icon={faPhone} />{/*+58 424-6328415*/}</a>
                        </div>
                    </div>

                    <div className="card-contactos">

                        <div className="icon"><FontAwesomeIcon className="material-icons md-36" icon={faEnvelope} /></div>
                        <p className="title">Correo Electronico</p>
                        <a className="text" href="mailto:info@lubricanteslamundial.com" target="_blank"><FontAwesomeIcon icon={faEnvelopeOpen} /></a>

                    </div>

                    <div className="card-contactos">

                        <div className="icon"><FontAwesomeIcon className="material-icons md-36" icon={faLocationDot} /></div>
                        <p className="title">Ubicación</p>
                        <a className="text" href="https://maps.app.goo.gl/Li2qrt865Y6EiFvS9" target="_blank"><FontAwesomeIcon icon={faMap} /></a>
                    </div>
                </div>
                <br /> <br /> <br />
            </div >
            <Footer />
        </>
    );
}

export default Contact;