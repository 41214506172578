import React, { useState, useEffect } from "react";
import '../pages/Mark.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

function Mark() {
    const hanldeClick = () => {
        alertify.alert(
            "Lubricantes La Mundial",
            "Actualmente este sitio se encuentra en construcción",
            function () {
                alertify.message("OK");
            }
        );
    };

    useEffect(() => {
        const handleImageLoad = () => {
            const spinner = document.querySelector('.spinner');
            if (spinner) {
                spinner.style.display = 'none';
            }
        };

        const handleMouseEnter = (event) => {
            const slide = event.currentTarget;
            const title = slide.querySelector('.flex-title');
            const about = slide.querySelector('.flex-about');

            if (title) {
                title.style.transform = 'rotate(0deg)';
                title.style.top = '10%';
            }

            if (about) {
                about.style.opacity = '1';
            }
        };

        const handleMouseLeave = (event) => {
            const slide = event.currentTarget;
            const title = slide.querySelector('.flex-title');
            const about = slide.querySelector('.flex-about');

            if (title) {
                title.style.transform = 'rotate(90deg)';
                title.style.top = '15%';
            }

            if (about) {
                about.style.opacity = '0';
            }
        };

        const flexSlides = document.querySelectorAll('.flex-slide');
        const flexContainer = document.querySelector('.flex-container');

        if (flexContainer) {
            flexContainer.addEventListener('load', handleImageLoad);
        }

        flexSlides.forEach((slide) => {
            slide.addEventListener('mouseenter', handleMouseEnter);
            slide.addEventListener('mouseleave', handleMouseLeave);
        });

        return () => {
            // Clean up event listeners when the component unmounts
            if (flexContainer) {
                flexContainer.removeEventListener('load', handleImageLoad);
            }

            flexSlides.forEach((slide) => {
                slide.removeEventListener('mouseenter', handleMouseEnter);
                slide.removeEventListener('mouseleave', handleMouseLeave);
            });
        };
    }, []);

    const [showModalGulf, setShowModalGulf] = useState(false);
    const [showModalValvoline, setShowModalValvoline] = useState(false);
    const [showModalArmax, setShowModalArmax] = useState(false);
    const [showModalMillard, setShowModalMillard] = useState(false);

    const handleCloseModalGulf = () => {
        setShowModalGulf(false);
    }

    const handleCloseModalValvoline = () => {
        setShowModalValvoline(false);
    }

    const handleCloseModalArmax = () => {
        setShowModalArmax(false);
    }

    const handleCloseModalMillard = () => {
        setShowModalMillard(false);
    }


    return (
        <>
            <body className="body-marcas">
                <div class="flex-container">

                    {/* Gulf */}
                    <div class="flex-slide gulf">
                        <div class="flex-title flex-title-home" ><img src="images/marcas/gulf-logo-v3.png" style={{ width: "45%", marginTop: "-10%" }} className="marcas" /></div>
                        <div class="flex-about flex-about-home">
                            <Button style={{ backgroundColor: "#002777", borderColor: "#002777", marginTop: "-45%" }} onClick={() => setShowModalGulf(true)}>
                                Leer Más
                            </Button>

                            <Modal size="xl" show={showModalGulf} onHide={handleCloseModalGulf} centered>
                                <Modal.Header style={{ backgroundColor: "#002777" }} closeButton>
                                    <Modal.Title style={{ color: "#fff" }} className="title-gulf">Gulf</Modal.Title>
                                </Modal.Header>
                                <Modal.Body><img src="images/modales/modal-gulf.webp" /></Modal.Body>
                                <Modal.Footer>
                                    <a className="enlace-gulf" target="_blank" href="https://gulf.com.ve/">
                                        <Button className="boton-gulf" style={{ backgroundColor: "#002777", borderColor: "#002777", color: "#fff" }}>
                                            Ir a Gulf Venezuela
                                        </Button>
                                    </a>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>

                    {/* Valvoline */}
                    <div class="flex-slide valvoline">
                        <div class="flex-title flex-title-home"><img src="images/marcas/valvoline-res.png" className="marcas" /></div>
                        <div class="flex-about flex-about-home">
                            <Button style={{ backgroundColor: "#0A82C6", borderColor: "#0A82C6" }} onClick={() => setShowModalValvoline(true)}>
                                Leer Más
                            </Button>

                            <Modal size="xl" show={showModalValvoline} onHide={handleCloseModalValvoline} centered>
                                <Modal.Header style={{ backgroundColor: "#0A82C6" }} closeButton>
                                    <Modal.Title style={{ color: "#fff" }} className="title-valvoline">Valvoline</Modal.Title>
                                </Modal.Header>
                                <Modal.Body><img src="images/modales/modal-valvoline.webp" /></Modal.Body>
                                <Modal.Footer>
                                    <a className="enlace-valvoline" target="_blank" onClick={hanldeClick}>
                                        <Button style={{ backgroundColor: "#0A82C6", borderColor: "#0A82C6", color: "#fff" }}>
                                            Ir a Valvoline Venezuela
                                        </Button>
                                    </a>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>

                    {/* Armax */}
                    <div class="flex-slide armax">
                        <div class="flex-title flex-title-home"><img src="images/marcas/armax-logo.png" style={{ width: "45%", marginTop: "-3%" }} className="marcas" /></div>
                        <div class="flex-about flex-about-home">
                            <Button style={{ backgroundColor: "#ED1C24", borderColor: "#ED1C24", marginTop: "-20%" }} onClick={() => setShowModalArmax(true)}>
                                Leer Más
                            </Button>

                            <Modal size="xl" show={showModalArmax} onHide={handleCloseModalArmax} centered>
                                <Modal.Header style={{ backgroundColor: "#ED1C24" }} closeButton>
                                    <Modal.Title style={{ color: "#fff" }} className="title-armax">Armax</Modal.Title>
                                </Modal.Header>
                                <Modal.Body><img src="images/modales/modal-armax.webp" /></Modal.Body>
                                <Modal.Footer>
                                    <a className="enlace-armax" target="_blank" onClick={hanldeClick}>
                                        <Button style={{ backgroundColor: "#ED1C24", borderColor: "#ED1C24", color: "#fff" }}>
                                            Ir a Armax Venezuela
                                        </Button>
                                    </a>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>

                    {/* Millard */}
                    <div class="flex-slide millard">
                        <div class="flex-title flex-title-home"><img src="images/marcas/millard-blue.png" className="marcas" /></div>
                        <div class="flex-about flex-about-home">
                            <Button style={{ backgroundColor: "#F59F19", borderColor: "#F59F19", marginTop: "-21%" }} onClick={() => setShowModalMillard(true)}>
                                Leer Más
                            </Button>

                            <Modal size="xl" show={showModalMillard} onHide={handleCloseModalMillard} centered>
                                <Modal.Header style={{ backgroundColor: "#F59F19" }} closeButton>
                                    <Modal.Title style={{ color: "#fff" }} className="title-millard">Millard</Modal.Title>
                                </Modal.Header>
                                <Modal.Body><img src="images/modales/modal-millard.webp" /></Modal.Body>
                                <Modal.Footer>
                                    <a className="enlace-millard" target="_blank" href="https://www.millardcatalog.com/en/applications/Venezuela">
                                        <Button style={{ backgroundColor: "#F59F19", borderColor: "#F59F19", color: "#fff" }}>
                                            Ir a Millard Venezuela
                                        </Button>
                                    </a>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>

                </div>
            </body>
        </>
    );
}

export default Mark;