import React from 'react';
import './App.css';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import Mark from './components/pages/Mark';
import Aboutus from './components/pages/Aboutus';
import Contact from './components/pages/Contact';
import Comercio_anzoategui from './components/pages/sections/Commerces/comercio-anzoategui';
import Comercio_barinas from './components/pages/sections/Commerces/comercio-barinas';
import Comercio_falcon from './components/pages/sections/Commerces/comercio-falcon';
import Comercio_lara from './components/pages/sections/Commerces/comercio-lara';
import Comercio_tachira from './components/pages/sections/Commerces/comercio-tachira';
import Comercio_trujillo from './components/pages/sections/Commerces/comercio-trujillo';
import Comercio_zulia from './components/pages/sections/Commerces/comercio-zulia';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/mark" component={Mark} />
          <Route path="/aboutus" component={Aboutus} />
          <Route path="/contact" component={Contact} />
          <Route path="/comercio-anzoategui" component={Comercio_anzoategui} />
          <Route path="/comercio-barinas" component={Comercio_barinas} />
          <Route path="/comercio-falcon" component={Comercio_falcon} />
          <Route path="/comercio-lara" component={Comercio_lara} />
          <Route path="/comercio-tachira" component={Comercio_tachira} />
          <Route path="/comercio-trujillo" component={Comercio_trujillo} />
          <Route path="/comercio-zulia" component={Comercio_zulia} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
